import { CSSProperties, InputHTMLAttributes, useState } from "react";

type TooltipProps = {
  message: string;
} & InputHTMLAttributes<HTMLDivElement>;

export default function Tooltip({ message, children, ...props }: TooltipProps) {
  const [visible, setVisible] = useState(false);

  return (
    <div {...props}>
      <div
        style={styles.tooltipContainer}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}>
        {children}
        {visible && <div style={styles.tooltip}>{message}</div>}
      </div>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  tooltipContainer: {
    position: "relative",
    display: "inline-block",
  },
  tooltip: {
    position: "absolute",
    bottom: "100%",
    left: "-25%",
    marginBottom: "8px",
    padding: "5px 10px",
    backgroundColor: "#333",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    zIndex: 100000,
  },
};
