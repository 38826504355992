import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from "react";
import Tooltip from "./Tooltip";

type InputProps = {
  label: string;
  tooltip?: string;
} & InputHTMLAttributes<HTMLInputElement>;

function InputWithLabelComp(
  { label, tooltip, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <label style={styles.label}>
      {tooltip ? (
        <Tooltip message={tooltip}>
          <span style={styles.toolTipText}>{label}</span>
        </Tooltip>
      ) : (
        label
      )}
      <input {...props} ref={ref} style={styles.input} />
    </label>
  );
}

const styles: { [key: string]: CSSProperties } = {
  label: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  toolTipText: {
    textDecoration: "underline dotted",
  },
};
const InputWithLabel = forwardRef(InputWithLabelComp);
export default InputWithLabel;
