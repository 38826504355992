import { CSSProperties } from "react";
import { TotalResults } from "../../interfaces/SolarPanelPerformanceInterfaces";
import ShadowBlockContainer from "../HtmlElements/ShadowBlockContainer";

type TotalResultsBlockProps = {
  totalResults?: TotalResults;
};

export default function TotalResultsBlock({
  totalResults,
}: TotalResultsBlockProps) {
  return (
    <>
      {totalResults && (
        <ShadowBlockContainer>
          <h3 style={styles.heading}>Results</h3>
          <p style={styles.resultItem}>
            <span style={styles.label}>Total Performance:</span>
            <span style={styles.value}>
              {totalResults?.panelPerformance.toFixed(2)} kW
            </span>
          </p>
        </ShadowBlockContainer>
      )}
    </>
  );
}

const styles: { [key: string]: CSSProperties } = {
  heading: {
    fontSize: "1.5rem",
    color: "#333",
    borderBottom: "2px solid #007bff",
    paddingBottom: "10px",
    marginBottom: "15px",
    textAlign: "center",
  },
  resultItem: {
    fontSize: "1.25rem",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0",
  },
  label: {
    fontWeight: "bold",
    color: "#555",
  },
  value: {
    color: "#007bff",
    fontWeight: "bold",
  },
};
