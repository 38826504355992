import { CSSProperties, PropsWithChildren } from "react";

export default function ShadowBlockContainer({ children }: PropsWithChildren) {
  return <div style={style}>{children}</div>;
}

const style: CSSProperties = {
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  border: "1px solid #ddd",
  maxWidth: "400px",
};
