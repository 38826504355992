import { sin, cos, dayOfYear } from "../helpers/mathHelpers";

class SolarTimeService {
  // Hour Angle (HRA)
  // https://www.pveducation.org/pvcdrom/properties-of-sunlight/solar-time
  public hourAngle(LST: number) {
    return 15 * (LST - 12);
  }

  // Local Solar Time
  public calcLST(lon: number, date: Date) {
    const d = dayOfYear(date);
    const LT = this.calcLT(date);
    const LSTM = this.calcLSTM(lon);
    const EoT = this.calcEoT(d);
    const TC = this.calcTC(lon, LSTM, EoT);

    return LT + TC / 60;
  }

  // Local Time
  public calcLT = (date: Date) => date.getHours() + date.getMinutes() / 60;

  // the difference of the Local Time (LT) from Universal Coordinated Time (UTC) in hours
  protected getTimezoneOffset = (lon: number): number => Math.round(lon / 15);

  // Local Standard Time Meridian (360 / 24 = 15, so 15 * offset)
  protected calcLSTM = (lon: number) => 15 * this.getTimezoneOffset(lon);

  // Equation of Time
  protected calcEoT(d: number) {
    const B = (360 * (d - 81)) / 365;
    return 9.87 * sin(2 * B) - 7.53 * cos(B) - 1.5 * sin(B);
  }

  // Time Correction Factor
  protected calcTC = (lon: number, LSTM: number, EoT: number) =>
    4 * (lon - LSTM) + EoT;
}
export const solarTimeService = new SolarTimeService();
