import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Header } from "./components/TemplateParts/Header";
import { CalculatorPage } from "./pages/CalculatorPage";
import { Scene3DPage } from "./pages/Scene3DPage";
import { MediaQueryProvider } from "./contexts/MediaQueryContext";

function App() {
  return (
    <MediaQueryProvider>
      <Router>
        <div className="App">
          {/* <Header /> */}
          <div className="content">
            <Routes>
              <Route path="/" element={<CalculatorPage />} />
              <Route path="/3dScene" element={<Scene3DPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </MediaQueryProvider>
  );
}

export default App;
