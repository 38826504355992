import { useFrame } from "@react-three/fiber";
import { Dispatch, SetStateAction } from "react";
import { solarAngleService } from "../../services/SolarAngleService";
import { solarTimeService } from "../../services/SolarTimeService";
import { cos, dayOfYear, sin } from "../../helpers/mathHelpers";

type SunModelProps = {
  setSunPath: Dispatch<SetStateAction<Array<[number, number, number]>>>;
};

const lat = 49.9808;
const lon = 36.2527;
const date = new Date();

export function SunModel({ setSunPath }: SunModelProps) {
  useFrame((state) => {
    const sphere = state.scene.getObjectByName("sphere");
    if (sphere) {
      date.setTime(date.getTime() + 3600 * 1000);
      const [targetX, targetY, targetZ] = sunPos(lat, lon, date);

      const { x, y, z } = sphere.position;

      sphere.position.x += targetX - x;
      sphere.position.y += targetY - y;
      sphere.position.z += targetZ - z;

      setSunPath((prevPath) => [...prevPath, [targetX, targetY, targetZ]]);
    }
  });

  return (
    <mesh name="sphere">
      <sphereGeometry args={[1, 32, 32]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
}

function sunPos(
  lat: number,
  lon: number,
  date: Date
): [number, number, number] {
  const d = dayOfYear(date);
  const delta = solarAngleService.solarDeclination(d);
  const LST = solarTimeService.calcLST(lon, date);
  const HRA = solarTimeService.hourAngle(LST);
  const alpha = solarAngleService.solarElevationAngle(lat, delta, HRA);
  const azimuth = solarAngleService.azimuthAngle(lat, alpha, delta, HRA, LST);

  // Convert polar coordinates (azimuth, elevation) to Cartesian coordinates (x, y, z)
  const distance = 10; // Distance from the scene center
  const x = distance * cos(alpha) * sin(azimuth);
  const y = distance * sin(alpha);
  const z = distance * cos(alpha) * cos(azimuth);

  console.log(date.toISOString(), azimuth, HRA, LST, x, y, z);

  return [x, y, z];
}
