import { CSSProperties, Dispatch, SetStateAction } from "react";
import { solarPerformanceService } from "../../services/SolarPerformanceService";
import { SubmitHandler, useForm } from "react-hook-form";
import { CalculatorFormDataType } from "../../types/CalculatorFormTypes";
import CalculatorSection1 from "./Sections/Section1";
import CalculatorSection2 from "./Sections/Section2";
import CollapsibleSection from "../HtmlElements/CollapsibleSection";
import { DataWithTotalResults } from "../../interfaces/SolarPanelPerformanceInterfaces";
import ShadowBlockContainer from "../HtmlElements/ShadowBlockContainer";
import Tooltip from "../HtmlElements/Tooltip";

type SolarPanelCalculatorProps = {
  calcFor: "day" | "month" | "year";
  onResults: Dispatch<SetStateAction<DataWithTotalResults>>;
};

export function SolarPanelCalculator({
  calcFor,
  onResults,
}: SolarPanelCalculatorProps) {
  const { register, handleSubmit, watch, setValue, reset } =
    useForm<CalculatorFormDataType>({
      defaultValues: {
        // date: new Date().toISOString().split("T")[0],
        // lat: 0,
        // lon: 0,
        // performanceRatio: 0.8,
      },
    });

  const exampleOnClick = () => {
    setValue("date", new Date().toISOString().split("T")[0]);
    setValue("lat", 49.9808);
    setValue("lon", 36.2527);

    setValue("power", 0.2);
    setValue("width", 0.77);
    setValue("height", 1.378);
    setValue("performanceRatio", 0.8);
  };

  const onSubmit: SubmitHandler<CalculatorFormDataType> = (data) => {
    const {
      date: dateStr,
      lat,
      lon,
      power,
      width,
      height,
      performanceRatio,
    } = data;
    const date = new Date(dateStr);

    // https://altshop.in.ua/products/sonyachna-panel-longi-lr5-72hph-535m
    const panelArea = solarPerformanceService.solarPanelArea(width, height);
    const panelEfficiency = solarPerformanceService.solarPanelEfficiency(
      power,
      panelArea
    );

    const positionProps = { lat, lon, date };
    const powerProps = { panelArea, panelEfficiency, performanceRatio };

    switch (calcFor) {
      case "day": {
        const dayPerformance = solarPerformanceService.dayPerformance(
          positionProps,
          powerProps
        );
        onResults(dayPerformance);
        break;
      }
      case "month": {
        const monthPerformance = solarPerformanceService.monthPerformance(
          positionProps,
          powerProps
        );
        onResults(monthPerformance);
        break;
      }
      case "year": {
        const yearPerformance = solarPerformanceService.yearPerformance(
          positionProps,
          powerProps
        );
        onResults(yearPerformance);
        break;
      }
    }
  };

  return (
    <ShadowBlockContainer>
      <div style={styles.btnContainer}>
        <Tooltip message="Clear form data">
          <button style={styles.clearBtn} onClick={() => reset()}>
            Clear
          </button>
        </Tooltip>
        <Tooltip message="Set example params">
          <button style={styles.exampleBtn} onClick={exampleOnClick}>
            Example
          </button>
        </Tooltip>
      </div>

      <form style={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <CalculatorSection1
          register={register}
          setValue={setValue}
          watch={watch}
        />
        <CollapsibleSection title={"Power"}>
          <CalculatorSection2
            register={register}
            setValue={setValue}
            watch={watch}
          />
        </CollapsibleSection>
        <button type="submit" style={styles.button}>
          Calculate
        </button>
      </form>
    </ShadowBlockContainer>
  );
}

const styles: { [key: string]: CSSProperties } = {
  btnContainer: {
    display: "flex",
    gap: 10,
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  clearBtn: {
    padding: "8px 12px",
    backgroundColor: "#FF2E2E",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  exampleBtn: {
    padding: "8px 12px",
    backgroundColor: "#2E77FF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  container: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 10,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    gap: "10px",
  },
  button: {
    padding: "10px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
