import { CSSProperties, PropsWithChildren } from "react";
import { useMediaQueryContext } from "../../contexts/MediaQueryContext";

export const InlineBlock = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMediaQueryContext();

  const inlineStyle: CSSProperties = {
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
    gap: "0 10px",
  };

  return <div style={inlineStyle}>{children}</div>;
};
