import { avgValue, dayOfYear } from "../helpers/mathHelpers";
import {
  DataResult,
  DataWithTotalResults,
} from "../interfaces/SolarPanelPerformanceInterfaces";
import { solarAngleService } from "./SolarAngleService";
import { solarTimeService } from "./SolarTimeService";

type PositonPanelPerformanceProps = {
  lat: number;
  lon: number;
  date: Date;
};

type PowerPanelPerformanceProps = {
  panelArea: number;
  panelEfficiency: number;
  performanceRatio: number;
};

// type AnglePanelPerformanceProps = {
//   tilt?: number;
//   azimuth?: number;
// };

class SolarPerformanceService {
  public selectedDateTimePerformance(
    position: PositonPanelPerformanceProps,
    power?: PowerPanelPerformanceProps
    // angle?: AnglePanelPerformanceProps
  ): DataResult {
    const { date, lat, lon } = position;
    const { panelArea, panelEfficiency, performanceRatio } = power ?? {};
    const isPower = panelArea && panelEfficiency && performanceRatio;

    const d = dayOfYear(date);
    const delta = solarAngleService.solarDeclination(d);

    const LT = solarTimeService.calcLT(date);
    const LST = solarTimeService.calcLST(lon, date);
    const HRA = solarTimeService.hourAngle(LST);
    const alpha = solarAngleService.solarElevationAngle(lat, delta, HRA);

    const I0 = solarAngleService.extraterrestrialSolarIrradiance(d);
    const teta = solarAngleService.zenithAngle(alpha);
    const irradiance = solarAngleService.solarIrradiance(I0, teta);

    const tilt = solarAngleService.tiltAngle(lat, delta);
    const azimuth = solarAngleService.azimuthAngle(lat, alpha, delta, HRA, LST);

    const panelPerformance = isPower
      ? this.solarPanelPerformance(
          irradiance,
          panelArea,
          panelEfficiency,
          performanceRatio
        )
      : undefined;

    return {
      num: LT,
      tilt,
      azimuth,
      panelPerformance,
    };
  }

  public dayPerformance(
    position: PositonPanelPerformanceProps,
    power?: PowerPanelPerformanceProps
    // angle?: AnglePanelPerformanceProps
  ): DataWithTotalResults {
    const { date: ddate, lat, lon } = position;

    const date = new Date(ddate);
    date.setHours(0, 0, 0, 0);
    const day = date.getDate();

    const results = [];
    while (date.getDate() === day) {
      const result = this.selectedDateTimePerformance(
        {
          lat,
          lon,
          date,
        },
        power
      );
      results.push(result);

      date.setTime(date.getTime() + 3600000);
    }
    const totalPerformance =
      results[0].panelPerformance !== undefined
        ? results.reduce(
            (prev, { panelPerformance }) => prev + (panelPerformance ?? 0),
            0
          )
        : undefined;

    return {
      data: results,
      ...(totalPerformance && {
        total: { panelPerformance: totalPerformance },
      }),
    };
  }

  public monthPerformance(
    position: PositonPanelPerformanceProps,
    power?: PowerPanelPerformanceProps
    // angle?: AnglePanelPerformanceProps
  ): DataWithTotalResults {
    const { date: ddate, lat, lon } = position;
    const { panelArea, panelEfficiency, performanceRatio } = power ?? {};
    const month = ddate.getMonth();
    const date = new Date(ddate);
    date.setFullYear(date.getFullYear(), month, 1);

    const isPowerParams = panelArea && panelEfficiency && performanceRatio;

    const results = [];
    while (date.getMonth() === month) {
      const result = this.dayPerformance(
        {
          lat,
          lon,
          date,
        },
        power
      );
      const avgResults = {
        num: date.getDate(),
        tilt: avgValue(result.data.map((item) => item.tilt)),
        azimuth: avgValue(result.data.map((item) => item.azimuth)),
        panelPerformance: isPowerParams
          ? result.data.reduce(
              (prev, { panelPerformance }) => prev + (panelPerformance ?? 0),
              0
            )
          : undefined,
      };
      results.push(avgResults);

      date.setDate(date.getDate() + 1);
    }
    const totalPerformance =
      results[0].panelPerformance !== undefined
        ? results.reduce(
            (prev, { panelPerformance }) => prev + (panelPerformance ?? 0),
            0
          )
        : undefined;

    return {
      data: results,
      ...(totalPerformance && {
        total: { panelPerformance: totalPerformance },
      }),
    };
  }

  public yearPerformance(
    position: PositonPanelPerformanceProps,
    power?: PowerPanelPerformanceProps
  ): DataWithTotalResults {
    const { date: ddate, lat, lon } = position;
    const { panelArea, panelEfficiency, performanceRatio } = power ?? {};
    const year = ddate.getFullYear();
    const date = new Date(ddate);
    date.setFullYear(year, 0);

    const isPowerParams = panelArea && panelEfficiency && performanceRatio;

    const results = [];
    while (date.getFullYear() === year) {
      const result = this.monthPerformance(
        {
          lat,
          lon,
          date,
        },
        power
      );
      const avgResults = {
        num: date.getMonth() + 1,
        tilt: avgValue(result.data.map((item) => item.tilt)),
        azimuth: avgValue(result.data.map((item) => item.azimuth)),
        panelPerformance: isPowerParams
          ? result.data.reduce(
              (prev, { panelPerformance }) => prev + (panelPerformance ?? 0),
              0
            )
          : undefined,
      };
      results.push(avgResults);

      date.setMonth(date.getMonth() + 1);
    }
    const totalPerformance =
      results[0].panelPerformance !== undefined
        ? results.reduce(
            (prev, { panelPerformance }) => prev + (panelPerformance ?? 0),
            0
          )
        : undefined;

    return {
      data: results,
      ...(totalPerformance && {
        total: { panelPerformance: totalPerformance },
      }),
    };
  }

  public solarPanelArea = (width: number, height: number) => width * height;

  // https://www.photonicuniverse.com/en/resources/articles/full/7.html
  public solarPanelEfficiency = (
    panelPower: number, // in kW
    panelArea: number // m²
  ) => (panelPower * 100) / panelArea;

  // https://www.pvmars.com/how-to-calculate-output-energy-of-pv-solar-systems/
  protected solarPanelPerformance(
    irradiance: number, // W/m²
    panelArea: number, // m²
    panelEfficiency: number, // in percents, e.g. 18%
    performanceRatio: number // Typical value between 0.75 and 0.9
  ) {
    // Convert efficiency to decimal if provided as a percentage
    const efficiencyDecimal = panelEfficiency / 100;

    // Calculate output in watts
    const outputPower =
      irradiance * panelArea * efficiencyDecimal * performanceRatio;

    return outputPower / 1000; // Output power in kW
  }
}
export const solarPerformanceService = new SolarPerformanceService();
