import { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { Object3D, MathUtils } from "three";

type SolarPanelProps = {
  url: string;
  rotationX: number;
};

export function SolarPanelModel({ url, rotationX }: SolarPanelProps) {
  const { scene } = useGLTF(url);
  const groupRef = useRef<Object3D | null>(null);
  const partRef = useRef<Object3D | null>(null);

  useEffect(() => {
    if (groupRef.current) {
      // const box = new THREE.Box3().setFromObject(groupRef.current);
      // const center = box.getCenter(new THREE.Vector3());
      // const size = box.getSize(new THREE.Vector3());
      groupRef.current.rotation.y = MathUtils.degToRad(-145);
      groupRef.current.position.set(0, 0, 0);
    }

    const panelPart = scene.getObjectByName("SolarSurface");
    if (panelPart) {
      partRef.current = panelPart;
    }
  }, [scene]);

  useEffect(() => {
    partRef.current?.rotation.set(MathUtils.degToRad(-rotationX), 0, 0);
  }, [rotationX]);

  return <primitive object={scene} ref={groupRef} />;
}
