import { CSSProperties, SetStateAction, useEffect, useState } from "react";
import { SolarPanelCalculator } from "../components/Calculator/SolarPanelCalculator";
import { SolarPerformanceTable } from "../components/Calculator/SolarPerformanceTable";
import { useMediaQueryContext } from "../contexts/MediaQueryContext";
import { DataWithTotalResults } from "../interfaces/SolarPanelPerformanceInterfaces";
import TotalResultsBlock from "../components/Calculator/TotalResultsBlock";

type selectValType = "day" | "month" | "year";

const selectOptions = [
  { name: "Day", value: "day" },
  { name: "Month", value: "month" },
  { name: "Year", value: "year" },
];

const staticTableHeaders = ["Tilt Angle (°)", "Azimuth (°)"];
const panelPerformanceHeader = "Panel Performance (kW)";

const defaultPerformance = { data: [] };

export function CalculatorPage() {
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<selectValType>("day");
  const { isTablet, isMobile } = useMediaQueryContext();

  const [performanceResults, setPerformanceResults] =
    useState<DataWithTotalResults>(defaultPerformance);

  const handlePerformanceResults = (
    results: SetStateAction<DataWithTotalResults>
  ) => {
    setPerformanceResults(results);
  };

  useEffect(() => {
    if (selectedUnit === "day")
      setTableHeaders(() => ["Hour (LT)", ...staticTableHeaders]);
    else if (selectedUnit === "month")
      setTableHeaders(() => ["Day", ...staticTableHeaders]);
    else if (selectedUnit === "year")
      setTableHeaders(() => ["Month", ...staticTableHeaders]);
  }, [selectedUnit]);

  useEffect(() => {
    if (!performanceResults.data.length) return;

    if (performanceResults.total?.panelPerformance !== undefined)
      setTableHeaders((prev) => [...prev.slice(0, 3), panelPerformanceHeader]);
    else setTableHeaders((prev) => [...prev.slice(0, 3)]);
  }, [performanceResults]);

  const styles: { [key: string]: CSSProperties } = {
    container: {
      marginTop: "100px",
      padding: "20px",
      display: "grid",
      gridTemplateColumns: isTablet || isMobile ? "1fr" : "1fr 2fr",
      gap: "10px",
    },
    sidebar: {
      zIndex: 1,
    },
    select: {
      height: "fit-content",
      margin: "25px 0 15px",
      padding: "10px",
      fontSize: "16px",
      width: "100%",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    block: {
      display: "flex",
      flexDirection: "column",
      position: "sticky",
      top: "20px",
      gap: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <select
          value={selectedUnit}
          onChange={(e) => {
            setSelectedUnit(e.target.value as selectValType);
            setPerformanceResults(defaultPerformance);
          }}
          style={styles.select}>
          {selectOptions.map(({ name, value }, index) => (
            <option key={index} value={value}>
              {name}
            </option>
          ))}
        </select>
        <div style={styles.block}>
          <SolarPanelCalculator
            calcFor={selectedUnit}
            onResults={handlePerformanceResults}
          />
          <TotalResultsBlock totalResults={performanceResults.total} />
        </div>
      </div>

      <div>
        <h1>Solar panel performance for {selectedUnit}</h1>
        <SolarPerformanceTable
          tableHeaders={tableHeaders}
          results={performanceResults.data}
        />
      </div>
    </div>
  );
}
