import { useState } from "react";
import { MenuController } from "../components/Scene3D/MenuController";
import { Scene3D } from "../components/Scene3D/Scene3D";

export function Scene3DPage() {
  const [rotationX, setRotationX] = useState<number>(45);

  return (
    <>
      <MenuController
        minRotation={0}
        maxRotation={60}
        rotationX={rotationX}
        setRotationX={setRotationX}
      />
      <Scene3D rotationX={rotationX} />
    </>
  );
}
