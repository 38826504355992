import { useRef, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Line, OrbitControls, Bounds, Environment } from "@react-three/drei";
import { PerspectiveCamera } from "three";
import { SolarPanelModel } from "./SolarPanel";
import { SunModel } from "./SunModel";

type SolarPanProps = {
  rotationX: number;
};

export function Scene3D({ rotationX }: SolarPanProps) {
  const cameraRef = useRef<PerspectiveCamera>(null);
  const [sunPath, setSunPath] = useState<Array<[number, number, number]>>([]);

  useEffect(() => {
    if (cameraRef.current) {
      const camera = cameraRef.current;

      camera.position.set(0, 2, 10);
    }
  }, []);

  return (
    <Canvas
      camera={{ position: [0, 2, 10], fov: 45 }}
      style={{ width: "100vw", height: "100vh" }}>
      <ambientLight intensity={0.6} />
      <directionalLight position={[5, 5, 5]} intensity={1} />

      {/* Create a sphere to represent the sun */}
      <SunModel setSunPath={setSunPath} />

      <Environment preset="city" />

      {/* TODO Нужно запретить вращение, пока идет приближение (Bounds) */}
      <Bounds fit clip observe>
        <SolarPanelModel url="/models/solar_panel.glb" rotationX={rotationX} />
      </Bounds>

      {/* Line following the sun */}
      {sunPath.length > 0 && (
        <Line points={sunPath} color="yellow" lineWidth={1} />
      )}

      <OrbitControls enableZoom={true} makeDefault target={[0, 0, 0]} />
      <axesHelper args={[1]} />
    </Canvas>
  );
}
