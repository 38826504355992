import { restrictInput } from "../../../helpers/formHelpers";
import { CalculatorSectionProps } from "../../../types/CalculatorFormTypes";
import InputWithLabel from "../../HtmlElements/InputWithLabel";
import { InlineBlock } from "../../HtmlElements/InlineBlock";
import { useEffect } from "react";

const dateParams = { required: true };
const latitudeParams = { required: true, min: -90, max: 90 };
const longitudeParams = { required: true, min: -180, max: 180 };

const dateName = "date";
const latitudeName = "lat";
const longitudeName = "lon";

export default function CalculatorSection1({
  register,
  setValue,
  watch,
}: CalculatorSectionProps) {
  const latitude = watch(latitudeName);
  const longitude = watch(longitudeName);

  useEffect(() => {
    setValue(latitudeName, restrictInput(latitude, -90, 90));
  }, [latitude, setValue]);

  useEffect(() => {
    setValue(longitudeName, restrictInput(longitude, -180, 180));
  }, [longitude, setValue]);

  return (
    <div>
      <InputWithLabel
        type="date"
        label="Date"
        {...register(dateName, dateParams)}
      />
      <InlineBlock>
        <InputWithLabel
          type="number"
          label="Latitude"
          placeholder="Latitude"
          step="0.0001"
          {...register(latitudeName, latitudeParams)}
        />
        <InputWithLabel
          type="number"
          label="Longitude"
          placeholder="Longitude"
          step="0.0001"
          {...register(longitudeName, longitudeParams)}
        />
      </InlineBlock>
    </div>
  );
}
