import { ComponentType, PropsWithChildren, useState } from "react";

type CollapsibleSectionProps = {
  title: string;
  isOpen?: boolean;
  toggleSection?: () => void;
} & PropsWithChildren;

const CollapsibleSection = ({
  title,
  children,
  isOpen,
  toggleSection,
}: CollapsibleSectionProps) => (
  <>
    <div style={styles.header} onClick={toggleSection}>
      <span style={styles.title}>{title}</span>
      <span style={styles.toggle}>{isOpen ? "▲" : "▼"}</span>
    </div>
    {isOpen && <div style={styles.content}>{children}</div>}{" "}
  </>
);

export function withCollapsible<P>(Component: ComponentType<P>) {
  return function CollapsibleWrapper(props: P) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSection = () => {
      setIsOpen((prev) => !prev);
    };

    return (
      <Component {...props} isOpen={isOpen} toggleSection={toggleSection} />
    );
  };
}
export default withCollapsible(CollapsibleSection);

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    fontWeight: "bold",
    padding: "5px 0",
  },
  title: {
    fontSize: "16px",
  },
  toggle: {
    fontSize: "16px",
  },
  content: {
    padding: "10px 0",
    borderTop: "1px solid #ccc",
  },
};
