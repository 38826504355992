import { useEffect } from "react";
import { restrictInput } from "../../../helpers/formHelpers";
import { CalculatorSectionProps } from "../../../types/CalculatorFormTypes";
import InputWithLabel from "../../HtmlElements/InputWithLabel";
import { InlineBlock } from "../../HtmlElements/InlineBlock";

const powerParams = { required: false, min: 0, max: 10 };
const widthParams = { required: false, min: 0, max: 10 };
const heightParams = { required: false, min: 0, max: 10 };
const ratioParams = { required: false, min: 0, max: 1 };

const powerName = "power";
const widthName = "width";
const heightName = "height";
const ratioName = "performanceRatio";

export default function CalculatorSection2({
  register,
  setValue,
  watch,
}: CalculatorSectionProps) {
  const power = watch(powerName);
  const width = watch(widthName);
  const height = watch(heightName);
  const ratio = watch(ratioName);

  useEffect(() => {
    setValue(powerName, restrictInput(power, powerParams.min, powerParams.max));
  }, [power, setValue]);

  useEffect(() => {
    setValue(widthName, restrictInput(width, widthParams.min, widthParams.max));
  }, [width, setValue]);

  useEffect(() => {
    setValue(
      heightName,
      restrictInput(height, heightParams.min, heightParams.max)
    );
  }, [height, setValue]);

  useEffect(() => {
    setValue(ratioName, restrictInput(ratio, ratioParams.min, ratioParams.max));
  }, [ratio, setValue]);

  return (
    <div>
      <InputWithLabel
        type="number"
        step="0.001"
        label="Panel Power (kW)"
        placeholder="Panel Power"
        {...register(powerName, powerParams)}
      />
      <InlineBlock>
        <InputWithLabel
          type="number"
          step="0.001"
          label="Panel Width (m)"
          placeholder="Panel Width"
          {...register(widthName, widthParams)}
        />
        <InputWithLabel
          type="number"
          step="0.001"
          label="Panel Height (m)"
          placeholder="Panel Height"
          {...register(heightName, heightParams)}
        />
      </InlineBlock>
      <InputWithLabel
        type="number"
        defaultValue={0.8}
        step="0.01"
        label="Performance Ratio"
        placeholder="Performance Ratio"
        tooltip="Typical value between 0.75 and 0.9"
        {...register(ratioName, ratioParams)}
      />
    </div>
  );
}
