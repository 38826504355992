import { createContext, useContext, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface MediaQueryContextType {
  isTablet: boolean;
  isMobile: boolean;
}

const MediaQueryContext = createContext<MediaQueryContextType | undefined>(
  undefined
);

export const MediaQueryProvider = ({ children }: { children: ReactNode }) => {
  const isTablet = useMediaQuery({
    query: "(min-width: 480px) and (max-width: 768px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <MediaQueryContext.Provider value={{ isTablet, isMobile }}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = (): MediaQueryContextType => {
  const context = useContext(MediaQueryContext);
  if (!context) {
    throw new Error(
      "useMediaQueryContext must be used within a MediaQueryProvider"
    );
  }
  return context;
};
