import { ChangeEvent, CSSProperties, SetStateAction } from "react";

type MenuControllerProps = {
  minRotation: number;
  maxRotation: number;
  rotationX: number;
  setRotationX: (value: SetStateAction<number>) => void;
};

export function MenuController({
  minRotation,
  maxRotation,
  rotationX,
  setRotationX,
}: MenuControllerProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= minRotation && value <= maxRotation) setRotationX(value);
  };

  return (
    <div style={styles.menu}>
      <h2 style={styles.title}>3D Scene Menu</h2>
      <label style={styles.label}>
        Angle (degrees):
        <input
          type="number"
          value={rotationX}
          onChange={handleChange}
          style={styles.input}
          min={minRotation}
          max={maxRotation}
        />
      </label>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  menu: {
    position: "absolute",
    top: 20,
    right: 20,
    padding: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 10,
  },
  title: {
    margin: 0,
    fontSize: "24px",
    color: "#333",
  },
  label: {
    display: "block",
    margin: "10px 0",
    fontSize: "16px",
    color: "#555",
  },
  input: {
    marginLeft: "10px",
    padding: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "60px",
  },
};
